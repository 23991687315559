@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-BoldItalic.woff2") format("woff2"),
    url("./fonts/Montserrat-BoldItalic.woff") format("woff"),
    url("./fonts/Montserrat-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-Black.woff2") format("woff2"),
    url("./fonts/Montserrat-Black.woff") format("woff"),
    url("./fonts/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-Regular.woff2") format("woff2"),
    url("./fonts/Montserrat-Regular.woff") format("woff"),
    url("./fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-LightItalic.woff2") format("woff2"),
    url("./fonts/Montserrat-LightItalic.woff") format("woff"),
    url("./fonts/Montserrat-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-Light.woff2") format("woff2"),
    url("./fonts/Montserrat-Light.woff") format("woff"),
    url("./fonts/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-SemiBoldItalic.woff2") format("woff2"),
    url("./fonts/Montserrat-SemiBoldItalic.woff") format("woff"),
    url("./fonts/Montserrat-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-ExtraBold.woff2") format("woff2"),
    url("./fonts/Montserrat-ExtraBold.woff") format("woff"),
    url("./fonts/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-Thin.woff2") format("woff2"),
    url("./fonts/Montserrat-Thin.woff") format("woff"),
    url("./fonts/Montserrat-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-Bold.woff2") format("woff2"),
    url("./fonts/Montserrat-Bold.woff") format("woff"),
    url("./fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-Medium.woff2") format("woff2"),
    url("./fonts/Montserrat-Medium.woff") format("woff"),
    url("./fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-ExtraLight.woff2") format("woff2"),
    url("./fonts/Montserrat-ExtraLight.woff") format("woff"),
    url("./fonts/Montserrat-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-ThinItalic.woff2") format("woff2"),
    url("./fonts/Montserrat-ThinItalic.woff") format("woff"),
    url("./fonts/Montserrat-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-SemiBold.woff2") format("woff2"),
    url("./fonts/Montserrat-SemiBold.woff") format("woff"),
    url("./fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-ExtraLightItalic.woff2") format("woff2"),
    url("./fonts/Montserrat-ExtraLightItalic.woff") format("woff"),
    url("./fonts/Montserrat-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-Italic.woff2") format("woff2"),
    url("./fonts/Montserrat-Italic.woff") format("woff"),
    url("./fonts/Montserrat-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-BlackItalic.woff2") format("woff2"),
    url("./fonts/Montserrat-BlackItalic.woff") format("woff"),
    url("./fonts/Montserrat-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-MediumItalic.woff2") format("woff2"),
    url("./fonts/Montserrat-MediumItalic.woff") format("woff"),
    url("./fonts/Montserrat-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-ExtraBoldItalic.woff2") format("woff2"),
    url("./fonts/Montserrat-ExtraBoldItalic.woff") format("woff"),
    url("./fonts/Montserrat-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

:root {
  // Antd transition
  --transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

:focus {
  outline: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.full-height {
  height: 100vh;
}

.center-box {
  display: flex;

  &.stack {
    flex-direction: column;
    align-items: center;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-split {
    justify-content: space-between;
  }

  &.align-center {
    align-items: center;
  }
}

// margin right 4 px.
.mr-4 {
  margin-right: 4px;
}

// margin right 8 px.
.mr-8 {
  margin-right: 8px;
}

.mr {
  margin-right: 1rem;
}

// margin-left: 1rem
.ml {
  margin-left: 1rem;
}

// margin-left: auto
.ml-auto {
  margin-left: auto;
}

.padding-36 {
  padding: 36px;
}

.padding-24 {
  padding: 24px;
}

.padding-4 {
  padding: 4px;
}

// hide menu item text when Sider is collapsed
.ant-menu-inline-collapsed .ant-menu-item span {
  opacity: 0;
}

.kwg-background {
  background-image: url("./logo-kwg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.background-dark-blue {
  background-color: #0a2946;
}

.background-primary-color {
  background-color: #2380b1;
}

.font-bold {
  font-family: "Montserrat";
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

// See Layout component.
#header-children-container {
  width: 100%;
  height: 100%;
  display: flex;

  & > div {
    display: flex;
  }
  .kwg-radio-group {
    display: inline-flex;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.kwg-radio-group .ant-radio-button-wrapper {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  height: 32px;
  line-height: 32px;
}

.kwg-radio-group.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #2780a5;
}

.kwg-radio-group label:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.kwg-radio-group label:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

// For title text, such as filter heading
//  or Table header title.
.title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px;
  color: #333333;
}

// Border
.border-right {
  // Similar to sider border
  // todo: use this in sider
  border-right: 1px solid #dfe1e6;
}

.border-bottom {
  border-bottom: 1px solid #dfe1e6;
}

@font-family: "Montserrat", sans-serif;@btn-font-weight: 500;@primary-color: #2380b1;@menu-item-font-size: 500;@layout-header-background: white;@layout-body-background: white;@btn-border-radius-base: 4px;@border-color-base: #dfe1e6;@border-radius-base: 4px;